<template>
  <g :fill="color" fill-rule="nonzero" transform="translate(-8, -8) scale(0.05, 0.05)">
    <path
      id="Path_29273"
      data-name="Path 29273"
      :fill="color"
      d="M237.69,503.31h324.62c8.15,0,14.76-6.61,14.76-14.76s-6.61-14.76-14.76-14.76h-22.92c-8-76.98-76.88-132.9-153.86-124.9-65.94,6.85-118.05,58.97-124.9,124.9h-22.93c-8.15,0-14.76,6.61-14.76,14.76s6.61,14.76,14.76,14.76ZM400,377.9c55.36.09,102.14,41.04,109.57,95.9h-219.14c7.4-54.88,54.19-95.85,109.57-95.95v.05Z"
    />
    <path
      id="Path_29274"
      data-name="Path 29274"
      :fill="color"
      d="M296.71,525.4c-8.15,0-14.76,6.61-14.76,14.76s6.61,14.75,14.76,14.75h206.57c8.15,0,14.76-6.61,14.76-14.75s-6.61-14.76-14.76-14.76h-206.57Z"
    />
    <path
      id="Path_29275"
      data-name="Path 29275"
      :fill="color"
      d="M385.24,259.8v44.27c.02,8.15,6.65,14.74,14.8,14.72,8.12-.02,14.69-6.6,14.71-14.72v-44.27c-.02-8.15-6.65-14.74-14.8-14.71-8.12.02-14.69,6.6-14.71,14.71Z"
    />
    <path
      id="Path_29276"
      data-name="Path 29276"
      :fill="color"
      d="M258.55,358.77c5.66,5.87,15,6.04,20.86.38,5.87-5.66,6.04-15,.38-20.86-.13-.13-.26-.26-.39-.39l-31.32-31.32c-5.96-5.56-15.3-5.23-20.85.74-5.29,5.68-5.28,14.49.03,20.15l31.29,31.31Z"
    />
    <path
      id="Path_29277"
      data-name="Path 29277"
      :fill="color"
      d="M551.87,306.61l-31.32,31.32c-5.87,5.65-6.05,14.99-.39,20.86,5.65,5.87,14.99,6.05,20.86.39.13-.13.26-.25.38-.38l31.32-31.32c5.76-5.76,5.76-15.11,0-20.87-5.76-5.76-15.11-5.76-20.87,0h.02Z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
